import styled from '@emotion/styled';
import { Checkbox, Radio } from 'antd';

import { min840, max1025, minTablet } from 'Utils/variables';

import colors, { button } from 'Utils/theme';

export const StyledWalmartButton = styled.button`
  ${(props) => (props.size ? (props.size === 'large' ? button.large : props.size === 'medium' ? button.medium : button.small) : button.walmartLarge)};
  ${(props) => (props.yellow ? button.walmartYellow : button.walmartDefault)};

  position: relative;
  padding: 0 30px;
  z-index: 2;

  &:focus {
    text-decoration: underline;
  }

  &:disabled {
    ${button.disabled};
  }
`;

export const StyledButtonSpinner = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  margin: 0 auto;

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${(props) => (props.color ? props.color : colors.walmart.blue)};
    border-top-color: transparent;
    animation: spinner 0.6s linear infinite;

    @keyframes spinner {
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export const StyledRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  &:first-of-type {
    padding-right: 5px;

    @media (min-width: ${minTablet}) {
      padding: 0 10px;
    }
  }

  & + & {
    padding-left: 5px;

    @media (min-width: ${minTablet}) {
      padding: 0 10px;
    }
  }

  @media (min-width: ${minTablet}) {
    flex: 1;
    padding: 0 10px;
  }
`;

export const StyledFormLabel = styled.span`
  color: ${colors.inHome.darkGrey};
  font-size: 14px;
  padding: 10px 0 4px;
  white-space: nowrap;
`;

export const StyledFormError = styled(StyledFormLabel)`
  color: ${colors.red[600]};
  letter-spacing: -0.034em;
`;

export const StyledFormRequiredMark = styled.span`
  color: ${colors.grey[800]};
  font-size: 14px;

  &:before {
    content: '*';
    position: relative;
  }
`;

export const StyledInput = styled.input`
  font-size: 16px;
  color: ${colors.walmart.blue};
  padding: 19px 10px;
  line-height: 1.375;
  border-style: none;
  box-shadow: ${(props) => (props.isValid ? colors.inHome.darkGrey : props.isValid === null ? colors.inHome.darkGrey : colors.red[600])} 0px 0px 0px 1px inset, ${colors.inHome.darkGrey} 0px 0px 0px 0px;
  -webkit-appearance: none;
  border-radius: 0;
  width: 100%;
  background-color: transparent;
  border-width: initial;
  border-color: initial;
  border-image: initial;
  z-index: 2;

  &:focus {
    box-shadow: ${colors.inHome.blue} 0px 0px 0px 1px, ${colors.blue[200]} 0px 0px 0px 4px !important;
  }
`;

export const StyledSelect = styled.select`
  font-size: 16px;
  padding: 19px;
  line-height: 1.375;
  background-color: ${colors.white};
  border-style: none;
  border-radius: 0;
  box-shadow: ${(props) => (props.isValid ? colors.inHome.darkGrey : props.isValid === null ? colors.inHome.darkGrey : colors.red[600])} 0px 0px 0px 1px inset, ${colors.inHome.darkGrey} 0px 0px 0px 0px;
  -webkit-appearance: none;
  z-index: 2;

  option:disabled > span {
    color: ${colors.inHome.medGrey} !important;
  }

  &:focus {
    box-shadow: ${colors.inHome.blue} 0px 0px 0px 1px, ${colors.blue[200]} 0px 0px 0px 4px !important;
  }

  &.include-arrow {
    background-image: linear-gradient(45deg, transparent 50%, ${colors.inHome.darkGrey} 50%), linear-gradient(135deg, ${colors.inHome.darkGrey} 50%, transparent 50%), linear-gradient(to right, ${colors.inHome.darkGrey}, ${colors.inHome.darkGrey});
    background-position: calc(100% - 22px) 26px, calc(100% - 16px) 26px;
    background-size: 6px 6px, 6px 6px, 0 0;
    background-repeat: no-repeat;
  }
`;

export const StyledHorizontalSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledHorizontalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;

  button {
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0 0 10px;
  width: 100%;
`;

export const StyledInputButton = styled(StyledWalmartButton)`
  height: 34px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const StyledCancelButton = styled.button`
  text-decoration: underline;
  margin-right: 30px;

  ${(props) => (props.disabled ? button.disabled : props.inModal ? button.inverted : button.cancel)};

  background-color: transparent;
  border: 0;
  z-index: 2;

  @media (min-width: ${max1025}) {
    margin-right: 40px;
  }

  svg {
    transform: rotate(90deg);
    width: 12px;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  position: relative;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  width: 100%;
  color: ${(props) => (props.disabled ? colors.inHome.grey : colors.walmart.blue)};
  font-weight: 400;

  margin-top: 0;
  margin-bottom: 9px;

  @media (min-width: ${min840}) {
    margin-top: 50px;
  }

  &.ant-checkbox-wrapper-checked {
    color: ${colors.walmart.blue};

    .ant-checkbox-inner {
      position: absolute;
      width: 12px;
      height: 12px;
      left: 6px;
      top: 6px;

      &:after {
        position: relative;
        top: -5px;
        display: inline-block;
        content: '';
        background-image: url('/static/svg/check.svg');
        background-size: 12px;
        background-repeat: no-repeat;
        width: 12px;
        height: 12px;
        color: ${colors.walmart.blue};
      }
    }
  }

  input {
    width: 23px;
    height: 23px;
    border-radius: 100%;
    border: 1px solid ${(props) => (props.disabled ? colors.inHome.grey : colors.walmart.blue)};
    -webkit-appearance: none;
    margin: 0;

    &:focus {
      outline: none;
    }
  }

  .ant-checkbox {
    position: relative;
    margin-right: 10px;
    top: 2px;
  }
`;

export const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (min-width: ${minTablet}) {
    flex-direction: ${(props) => (props.inCta ? 'column' : 'row')};
    flex-wrap: wrap;
    justify-content: ${(props) => (props.inCta ? 'center' : 'flex-start')};
  }

  span.ant-radio {
    display: none;
  }
`;

export const StyledRadio = styled(Radio)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.inCta ? '0' : '10px 0')};

  @media (min-width: ${minTablet}) {
    max-width: ${(props) => (props.inModal ? 'calc(100% / 2)' : 'calc(100% / 3)')};
    min-width: 280px;
    padding: ${(props) => (props.inCta ? '0' : '10px')};
  }

  span {
    height: 100%;
  }
`;
